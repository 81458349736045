import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useSettings from './useSettings';
// config
import { allLangs, defaultLang } from '../config';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const params = useParams();

  const { onChangeDirectionByLang } = useSettings();

  const defaultCountry = process?.env?.REACT_APP_SUB_DOMAINS ? process?.env?.REACT_APP_SUB_DOMAINS?.split(',')[0] : '';

  const langStorage = localStorage.getItem('LngDomain') || params?.domain || defaultCountry;

  const currentLang = allLangs.find((_lang) => _lang?.domain === langStorage);

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
    onChangeDirectionByLang(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text, options) => translate(text, options),
    currentLang,
    allLangs,
  };
}
