import { useState, useEffect, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
// form
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
// @mui
import { Stack, IconButton, InputAdornment, Alert, Box, Typography, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
// hooks
import { LoadingButton } from '@mui/lab';
import { subYears } from 'date-fns';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import useAuth from '../../../hooks/useAuth';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFCheckbox, RHFPhonenumber, RHFSwitch, RHFTextField } from '../../../components/hook-form';
import { useDispatch } from '../../../redux/store';
import { closeRegister } from '../../../redux/slices/authPages';
import useLocales from '../../../hooks/useLocales';
import RHFDatePicker from '../../../components/hook-form/RHFDatePicker';
import useRedirect from '../../../hooks/useRedirect';
import RegisterOTPForm from './RegisterOTPForm';
import { getCountryFromUrl, getOtpErrorMessage } from '../../../utils/formatString';
import firebaseUtil from '../../../utils/firebaseUtil';
import { IS_DEV } from '../../../config';

// ----------------------------------------------------------------------

const FormContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(45deg, rgba(255,191,102,1) 0%, rgba(255,191,102,1) 20%, rgba(255,64,62,1) 40%)',
  padding: '16px',
  borderRadius: '10px',
  border: '2px solid red',
  '& .MuiFormHelperText-root': {
    color: 'yellow !important',
  },
}));

export default function RegisterForm() {
  const { register } = useAuth();

  const { translate } = useLocales();

  const navigate = useRedirect();

  const country = getCountryFromUrl();

  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);

  const [verification, setVerification] = useState(null);

  const [formError, setFormError] = useState('');

  const RegisterSchema = Yup.object().shape({
    profileNoHp: Yup.string()
      .required(translate('x_is_required', { x: translate('phone_number') }))
      .test('is-valid-phone', translate('invalid_x', { x: translate('phone_number') }), (value) => {
        if (IS_DEV) return isPossiblePhoneNumber(value);
        return isValidPhoneNumber(value);
      }),
    // email: Yup.string()
    //   .email(translate('invalid_x', { x: translate('email') }))
    //   .required(translate('x_is_required', { x: translate('email') })),
    fullName: Yup.string().required(translate('x_is_required', { x: translate('full_name') })),
    password: Yup.string().required(translate('x_is_required', { x: translate('password') })),
    // birthday: Yup.string()
    //   .nullable()
    //   .required(translate('x_is_required', { x: translate('birthday') })),
    // captcha: Yup.string().required(translate('x_is_required', { x: translate('captcha') })),
    confirm18: Yup.bool().oneOf([true], translate('x_is_required', { x: translate('this_field') })),
  });

  // useEffect(() => {
  //   return () => localStorage.removeItem('referralCode');
  // }, []);

  const defaultValues = {
    username: '',
    profileNoHp: '',
    email: '',
    fullName: '',
    password: '',
    referralCode: localStorage.getItem('referralCode') || '',
    birthday: '',
    captcha: '',
    confirm18: false,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    setError,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = methods;

  const values = watch();

  useEffect(() => {
    setTimeout(() => {
      loadCaptchaEnginge(4);
    }, 100);
  }, []);

  const isValidCaptcha = useMemo(() => validateCaptcha(values.captcha, false), [values.captcha]);

  const onSubmit = async (data) => {
    try {
      if (!isValid) return;

      if (country !== 'inr') await verifyOtp();

      const newData = JSON.parse(JSON.stringify(data));
      if (!validateCaptcha(data?.captcha, false)) throw new Error(translate('invalid_captcha'));

      // delete newData.captcha;
      const formattedDob = moment(newData?.birthday).format('YYYY-MM-DD');
      await register({
        ...newData,
        birthday: formattedDob,
      });
      reset();

      navigate('/');
    } catch (error) {
      console.error(error);
      // if (isMountedRef.current) {
      setFormError(translate(error.message));
      // }
    }
  };

  const verifyOtp = async () => {
    try {
      if (verification?.id) {
        await firebaseUtil.handleVerifyOTP(verification?.id, verification?.otp);
        setVerification((prev) => ({ ...prev, isVerified: true }));
      }
    } catch (error) {
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(getOtpErrorMessage(translate, error), snackbarOptions);
      throw new Error(getOtpErrorMessage(translate, error));
    }
  };

  return (
    <FormContainer>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* <Box sx={{ width: '65%' }}> */}
        <Stack spacing={2}>
          {!!formError && <Alert severity="error">{formError}</Alert>}

          {/* <RHFTextField
            name="username"
            placeholder={translate('username')}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="mdi:user" sx={{ fontSize: '20px', color: 'red' }} />
                </InputAdornment>
              ),
            }}
          /> */}

          <RHFTextField
            name="fullName"
            placeholder={translate('full_name')}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="teenyicons:id-solid" sx={{ fontSize: '20px', color: 'red' }} />
                </InputAdornment>
              ),
            }}
          />

          <RHFPhonenumber
            name="profileNoHp"
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: 0,
              },
            }}
            // onChange={(value) => {
            //   methods.setValue('profileNoHp', value);
            //   methods.trigger('profileNoHp');
            // }}
          />

          {/* <RHFTextField
            name="email"
            placeholder={translate('email')}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="ic:baseline-email" sx={{ fontSize: '20px', color: 'red' }} />
                </InputAdornment>
              ),
            }}
          />

          <RHFDatePicker
            name="birthday"
            InputSx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: 0,
              },
            }}
            openTo="year"
            maxDate={subYears(new Date(), 18)}
            InputProps={{
              placeholder: translate('birthday'),
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="mingcute:birthday-2-fill" sx={{ fontSize: '20px', color: 'red' }} />
                </InputAdornment>
              ),
            }}
          /> */}
          {/* <Typography style={{ fontSize: '.75rem', color: 'white', fontWeight: 500 }}>
            {translate('birthday_bonus')}
          </Typography> */}

          <RHFTextField
            name="password"
            placeholder={translate('password')}
            type={showPassword ? 'text' : 'password'}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="material-symbols:lock" sx={{ fontSize: '20px', color: 'red' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField
            name="referralCode"
            disabled={localStorage?.getItem('referralCode')}
            placeholder={`${translate('referral_code')}(${translate('optional')})`}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="majesticons:share" sx={{ fontSize: '20px', color: 'red' }} />
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField
            name="captcha"
            placeholder={translate('captcha')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LoadCanvasTemplateNoReload />
                </InputAdornment>
              ),
            }}
          />

          {country !== 'inr' ? (
            <RegisterOTPForm
              phoneNumber={values.profileNoHp}
              setVerification={setVerification}
              verification={verification}
              isValidCaptcha={isValidCaptcha}
            />
          ) : (
            ''
          )}

          <RHFCheckbox
            name={'confirm18'}
            label={
              <Typography fontSize={'12px'}>
                {translate('confirm_18')}{' '}
                <Typography fontSize={'inherit'} component={'span'} sx={{ textDecoration: 'underline' }}>
                  {translate('terms_of_service')}
                </Typography>
              </Typography>
            }
          />

          <LoadingButton
            fullWidth
            variant="contained"
            type={'submit'}
            loading={isSubmitting}
            disabled={isSubmitting || (country !== 'inr' && !verification?.id) || !isValid}
            className="red-gradient-btn"
            sx={{
              textShadow: '0px 1px 4px #000',
              '&:disabled': {
                filter: 'grayscale(0.8)',
              },
            }}
          >
            {translate('register')}
          </LoadingButton>

          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              {translate('already_have_an_account')}{' '}
              <Typography
                onClick={() => navigate('/auth/login')}
                fontSize={'inherit'}
                component={'span'}
                fontWeight={700}
                sx={{ textDecoration: 'underline' }}
              >
                {translate('login')}
              </Typography>
            </Typography>
          </Box>
        </Stack>
        {/* </Box> */}
      </FormProvider>
    </FormContainer>
  );
}
