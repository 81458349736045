import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, Grid, Container } from '@mui/material';
// components
import Image from '../../components/Image';
// sections
import { ChangePasswordForm } from '../../sections/auth/change-password';
import SocialIcons from '../../sections/about/SocialIcons';
import useLocales from '../../hooks/useLocales';
import { LoginStylesByLocale } from '../../components/nav-section/vertical/style';

// ----------------------------------------------------------------------

const ContentStyle = styled(Stack)(({ theme }) => ({
  // maxWidth: 480,
  marginRight: 'auto',
  marginLeft: 'auto',
  // minHeight: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
}));

// ----------------------------------------------------------------------

export default function ChangePassword() {
  const { translate, currentLang } = useLocales();

  return (
    <ContentStyle spacing={3}>
      <Typography variant="h4" fontWeight={900} sx={{ color: 'red' }}>
        {translate('change_password')}
      </Typography>

      <Container maxWidth="xs" sx={{ p: 0 }}>
        <ChangePasswordForm />
      </Container>
    </ContentStyle>
  );
}
