import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, Box, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import { useSnackbar } from 'notistack';
// components
import { FormProvider, RHFPassword } from '../../../components/hook-form';
import conObj from '../../../utils/connection-assistant';
import useLocales from '../../../hooks/useLocales';
import useRedirect from '../../../hooks/useRedirect';

// ----------------------------------------------------------------------
const FormContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(45deg, rgba(255,191,102,1) 0%, rgba(255,191,102,1) 20%, rgba(255,64,62,1) 40%)',
  padding: '16px',
  borderRadius: '10px',
  border: '2px solid red',
  '& .MuiFormHelperText-root': {
    color: 'yellow !important',
  },
}));

export default function ChangePasswordForm() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useRedirect();

  const { translate } = useLocales();

  const ChangePwdSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(6, 'Current password must be at least 6 characters')
      .required('Current password is required'),
    newPassword: Yup.string().min(6, 'New Password must be at least 6 characters').required('New Password is required'),
    confirmNewPassword: Yup.string().when('newPassword', {
      is: (val) => !!val,
      then: Yup.string()
        .nullable()
        .oneOf([Yup.ref('newPassword')], "Password doesn't match")
        .min(6, 'Confirm New Password must be at least 6 characters')
        .required('Confirm New Password is required'),
    }),
  });

  const defaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePwdSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await conObj.post('password.json', {
        'user-current_password': data?.currentPassword,
        'user-new_password': data?.newPassword,
        'user-new_password_confirmation': data?.confirmNewPassword,
      });
      reset();
      navigate('/');
      enqueueSnackbar('Password changed');
    } catch (error) {
      reset();
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <FormContainer>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <RHFPassword name={'currentPassword'} placeholder={translate('current_password')} size="small" />

          <RHFPassword name={'newPassword'} placeholder={translate('new_password')} size="small" />

          <RHFPassword name={'confirmNewPassword'} placeholder={translate('confirm_new_password')} size="small" />

          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            className="red-gradient-btn"
            sx={{ textShadow: '0px 1px 4px #000' }}
            loading={isSubmitting}
          >
            {translate('submit')}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </FormContainer>
  );
}
