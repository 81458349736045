// ----------------------------------------------------------------------

// 如果這個翻譯不正確，請忽略，這個翻譯僅供演示用途
// 如果您能幫助改進翻譯，請發送電子郵件至 support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  // 登錄
  login: `登錄`,

  // 註冊
  register: `註冊`,

  // 主頁
  game_partners: `遊戲合作夥伴`,
  responsible_gaming: `負責任遊戲`,
  supported_bank: `支持的銀行`,
  load_more: `加載更多`,

  // 遊戲
  choose_your_favourite_x_games: `選擇你最喜愛的 {{x}} 個遊戲`,
  choose_your_favourite_x: `選擇你最喜愛的 {{x}}`,
  launch: `啟動`,

  // 通知
  notifications: `通知`,
  you_have_x_unread_messages: `你有 {{x}} 條未讀消息`,

  // 存款/取款
  drop_or_select_file: `拖放或選擇文件`,
  drop_files_here: `將文件拖到這裡`,
  bank: `銀行`,
  bank_name: `銀行名稱`,
  account_name: `賬戶名稱`,
  account_number: `賬戶號碼`,
  bank_account: `銀行賬戶`,
  something_went_wrong: `出了一些問題。請聯繫支持部門。`,
  deposit_amount_between_x_and_y: `存款金額必須在 {{x}} 和 {{y}} 之間`,
  minimum_amount_is_x_y: `最低金額是 {{x}}{{y}}`,
  payment_method: `支付方式`,
  mode: `模式`,
  transfer_to: `轉賬到`,
  select: `選擇`,
  account: `賬戶`,
  amount: `金額`,
  deposit: `存款`,
  withdraw: `取款`,
  amount_no_exceed_balance: `取款金額不應超過可用餘額`,
  minimum_withdraw_amount_is_x: `最低取款金額是 {{x}}`,
  maximum_withdraw_amount_is_x: `最高取款金額是 {{x}}`,
  available_balance: `可用餘額`,
  amount_withdrawn: `已提取金額`,
  no_bank_account: `你沒有可用的銀行賬戶，現在添加`,
  add_account: `添加賬戶`,
  account_name_must_be_4: `賬戶名稱長度必須大於4`,
  please_select_bank: `請選擇一個銀行賬戶`,
  account_number_must_be_x: `賬戶號碼長度必須為6-10`,
  minimum_length_is_x: `最小長度為 {{x}}`,
  maximum_length_is_x: `最大長度為 {{x}}`,
  withdraw_information: `取款信息`,
  account_holder_name: `賬戶持有人名稱`,
  name_of_the_account_holder: `賬戶持有人的名字`,
  fill_in_bank_account: `填寫銀行賬戶信息`,
  please_upload_receipt: `請上傳收據`,
  deposit_bonus: `存款獎金`,
  bank_transfer: `銀行轉賬`,
  upload_receipt: `上傳收據`,
  select_bonus: `選擇獎金`,
  no_promotion: `無促銷活動`,

  // 交易記錄
  transaction_history: `交易記錄`,

  // 投注記錄
  bet_history: `投注記錄`,
  game_provider: `遊戲提供者`,
  bet_count: `投注次數`,
  bet_amount: `投注金額`,
  win_loss: `盈虧`,
  rebate: `回扣`,
  rebates: `回扣`,
  turnover: `營業額`,
  game_category: `遊戲類別`,

  // 全部
  all: `全部`,
  x_is_required: `{{x}} 是必需的`,
  note: `備註`,
  save: `保存`,
  please_select_x: `請選擇 {{x}}`,
  title: `標題`,
  date: `日期`,
  type: `類型`,
  status: `狀態`,
  remark: `備註`,
  clear: `清除`,
  date_from: `開始日期`,
  date_to: `結束日期`,
  category: `類別`,
  search: `搜索`,
  new: `新`,
  change_password: `更改密碼`,
  logout: `登出`,
  username: `用戶名`,
  password: `密碼`,
  forgot_password: `忘記密碼`,
  confirm: `確認`,
  dont_have_account: `沒有用戶賬戶？`,
  contact_us: `聯繫我們`,
  game_menu: `遊戲菜單`,
  bonus: '獎金',
  no_data: `無數據`,
  terms_and_condition: '條款與條件',
  game_list: '遊戲列表',
  play: '遊戲',
  games: '遊戲',
  casino: '賭場',
  slots: '老虎機',
  card: '卡牌',
  fishing: '捕魚',
  sport: '運動',
  sports: '運動',
  bonuses: '獎金',
  account_profile: `賬戶資料`,
  email: `電子郵件`,
  full_name: `全名`,
  phone_number: `電話號碼`,
  level: `等級`,
  bank_list: `銀行列表`,
  in_maintenance: `維護中`,
  birthday: `生日`,
  birthday_bonus: `輸入你的生日以獲取獎金！🎉🎁`,
  referral_code: `推薦碼`,
  invalid_x: `無效的 {{x}}`,
  extra: `額外`,
  referral: `推薦`,
  my_referral: `我的推薦`,
  my_claim: `我的申請`,
  claim_profit: `申請收益`,
  available_profit: `可用收益`,
  claimed_profit: `已申請收益`,
  all_time_profit: `所有時間收益`,
  referral_note_on_x: `每當你的朋友在 {{x}} 上玩耍時獲得收益`,
  tier: `等級`,
  commission: `佣金`,
  referred_member: `被推薦會員`,
  profit_earned: `賺取的收益`,
  account_information: `賬戶信息`,
  invite_friends: `邀請朋友`,
  how_it_work: `如何運作？`,
  invite_note: `當你的朋友使用你的推薦碼註冊時，獲得獨家推薦獎金！`,
  share_via: `通過以下方式分享`,
  referral_link: `推薦鏈接`,
  total_profit: `總收益`,
  copied: `已復制`,
  nothing_to_claim: `無可申請`,
  claim_history: `申請歷史`,
  from: `從`,
  to: `到`,
  choose_date: `選擇日期`,
  select_both_date: `選擇開始和結束日期`,
  hello: '你好',
  my_downline: `我的下線`,
  downline: `下線`,
  forgot_password_contact: '請聯繫下方的客服支持，以幫助你更改密碼',
  submit: '提交',
  current_password: '當前密碼',
  new_password: '新密碼',
  confirm_new_password: '確認新密碼',
  already_have_an_account: '已經有賬戶？',
  share: '分享',
  more_info: '更多信息',
  copy_link: '復制鏈接',
  share_to_friend: '分享給朋友',
  get_bonus: '獲取獎金',
  copy_your_referral: '復制你的推薦鏈接並發送到社交媒體，並從你的下線中獲得積分！',
  last_x_digit_bank_ref_number: `銀行參考號碼的最後 {{x}} 位數`,
  bank_ref_number_validation_x: `銀行參考號碼是必需的，並且必須為 {{x}} 個字符長`,
  card_game: '卡牌遊戲',
  e_sports: '電子競技',
  instant_game: '即時遊戲',
  sort: '排序',
  name: '名稱',
  default: '默認',
  e_sports_bonus: '電子競技獎金',
  fishing_bonus: '捕魚獎金',
  casino_bonus: '賭場獎金',
  sports_bonus: '運動獎金',
  slots_bonus: '老虎機獎金',
  instant_game_bonus: '即時遊戲獎金',
  card_game_bonus: '卡牌遊戲獎金',
  all_bonus: '所有獎金',
  how_to_get_referral_bonus: '如何獲得推薦好友獎金？',
  steps_to_claim: '申請獎金的步驟',
  share_your_referral_code:
    '1. 将您的推荐代码分享给您的朋友，以通过 Viber、Line、Telegram、WhatsApp、Facebook 或 Messenger 注册帐户。',
  after_friend_register: '2. 朋友使用 New365 注册后，推荐者和被推荐者都需要进行存款以领取此奖金。',
  refer_a_friend_bonus: '3. 推荐朋友奖金计算:',
  turnover_played: '流水 x 佣金 % = 推荐朋友奖金',
  example: '示例:',
  turnover_formula_x: '流水（1,000,000）x 0.002%（佣金 %）= 2,000{{x}}',
  note_bonus_only: '*注意: 奖金只能在次日凌晨12点后申领。',
  note_bonus_only_x: '*注意: 奖金只能在{{x}}次日凌晨12点后申领。',
  access_dashboard: '4. 访问仪表板查看应得利润、佣金层级、申领历史和下线。',
  click_this_button_to_claim: '点击此按钮领取推荐朋友奖金',
  commision_tier: '佣金层级',
  member_downline: '下线会员',
  bonus_tutorial_video: '奖金教程视频',
  popularity: '热门程度',
  no_balance: '您的余额不足，无法继续操作',
  invalid_captcha: '无效的验证码',
  captcha: '验证码',
  claim_bonus: '领取奖金',
  claimed: '已领取',
  bonus_claimed: '奖金已领取',
  online_banking: '在线银行',
  please_enter_email: '请输入您的电子邮箱地址以继续重置密码流程',
  please_select_a_bank: '请选择一个银行',
  deposit_from_bank: '从银行存款',
  from_selected_promotion: '从选定的促销活动',
  from_selected_bank: '从选定的银行',
  press_get_code: '点击“获取验证码”按钮，通过短信接收验证码',
  otp_sent: '验证码已发送！请输入下方的验证码以继续',
  get_code: '获取验证码',
  verify: '验证',
  verification_code: '验证码',
  otp_not_found: '未找到 OTP 功能！',
  network_error: '网络错误',
  we_have_sent_otp: '我们已向您的手机号码发送了一条 OTP',
  your_otp_expire: '您的 OTP 将在',
  didnt_receive: '没有收到消息？',
  send_again: '重新发送',
  resend_again: '再次发送',
  invalid_verification: '无效的验证码！',
  otp_expired: 'OTP 已过期，请重新输入您的手机号码并重试！',
  otp_expired_redirect: 'OTP 已过期！正在重定向到登录页面',
  captcha_check_failed: '验证码检查失败！',
  verify_phonenumber: '验证手机号码',
  send_otp: '发送 OTP',
  success_verification: '您的手机号码已验证！请填写下面的表格以完成注册。',
  sign_up: '注册',
  refer_and_reward: '推荐和奖励',
  minor_note: `访问本网站时，请确保您年满18岁并同意`,
  terms_of_service: '服务条款',
  confirm_18: `我确认我已年满18岁，并已阅读`,
  this_field: '此字段',
  slots_description: `老虎机通常有三个或更多“轴”，每个轴上都有一些数字...`,
  play_now: `立即游戏`,
  live_casino: `真人赌场`,
  promotion: `促销`,
  promotions: `促销活动`,
  profile: `个人资料`,
  wallet: `钱包`,
  cock_fighting: `斗鸡`,
  reset_password: '重置密码',
  home: `首页`,
  manage_your_bank_accounts: `管理您的银行账户`,
  history_of_deposit_withdraw_transactions: `存款/取款交易历史记录`,
  transaction_type: `交易类型`,
  period_history: `历史周期`,
  reset: `重置`,
  learn_more: `了解更多`,
  number_exists: '此电话号码已注册！',
  number_not_exists: '此电话号码未注册！',
  ifsc_code: `IFSC代码`,
  fil_in_ifsc_code: `填写 IFSC 代码`,
  optional: `选修的`,
  app_download: `应用程序下载`,
  payment_draft: '如果您确认已付款，请稍等片刻，因为付款可能仍在处理中',
  be_a_vip: `成为VIP`,
  experience_exlusivity: `体验独特的专属服务，享受特别促销、礼品和返利 - 超出你的预期！`,
  become_vip1: `作为马来西亚最具创新性和全方位的在线游戏平台，NEW365 不断努力为会员提供最佳服务。从游戏选项到服务标准，NEW365 提供最新和最优质的体验。`,
  become_vip2: `为了提升体验，NEW365 正在推出一项专属VIP计划，旨在为忠实会员提供更多理由继续信任我们作为他们的娱乐提供者。构成 NEW365 VIP 计划骨干的五个等级分别是：铜牌、银牌、金牌、铂金牌和钻石牌。作为 NEW365 VIP 会员，您将享受专属优惠、礼品和奖金，以及优先服务——一切您所需的，来获得卓越的游戏体验。特殊的返利率和等级升级奖金可能会帮助您实现游戏目标！`,
  become_vip3: `设立等级制计划的目的是为了激励会员逐步升级。虽然我们鼓励会员达成VIP等级要求并不断提升，但我们始终关注会员的满意度，特别是VIP会员。因为您的满意是我们主要的关注点。`,
  to_all_member: `首次升级的所有会员`,
  vip_journey1: `1. 定期会员在满足存款要求后，将自动升级为VIP会员，具体时间请在规定时间内完成。`,
  vip_journey2: `2. 允许系统在2个工作日内验证和更新您的数据。`,
  vip_journey3: `3. 放松身心，开始享受您的VIP奖励！`,
  benefits_as_our_vip: `作为我们的VIP的福利`,
  vip_upgrading1: `为了您的安全和保障，您需要提供 NEW365 VIP 升级政策中所述的所有必要信息。我们的团队将需要最多2个工作日来审查和验证提供的信息。然后，我们会在系统中更新您的最新状态和信息，之后，您的VIP会员旅程将正式开始！`,
  vip_upgrading2: `为了确保升级过程顺利高效，请遵守所有升级规定。如需帮助，请随时联系客户服务人员。`,
  no_data_available: `暂无数据`,
  how_does_it_work: `如何运作？`,
  new_vip_journey: `NEW365 VIP 旅程`,
  vip_benefits: `VIP 福利`,
  faq: `常见`,
  membership_renewal: `会员续订`,
  about: `关于`,
  upgrade: `升级`,
  benefits: `福利`,
  special_rebate: `特别返利`,
  join_now: '立即加入'

  // TODO: Translate to OTHER LANG
};

export default cn;
