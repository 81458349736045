import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import React, { forwardRef, useEffect, useState } from 'react';
// @mui
import { Box } from '@mui/material';
import { t } from 'i18next';

import { useSelector } from '../redux/store';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  const { siteSettings } = useSelector((x) => x.lookup);

  return (
    <>
      <Helmet>
        <title data-react-helmet="true">{`${
          siteSettings?.config_meta_title ||
          'New365online is the biggest and most trusted platform with a comprehensive game selection in Myanmar. Sign up now enjoy our rewards and promotions.'
        }`}</title>
        {meta}
        <meta name="description" data-react-helmet="true" content={siteSettings?.config_meta_desc} />;
        {/* <script type="text/javascript">{`\r\nvar Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();\r\n(function(){\r\nvar s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];\r\ns1.async=true;\r\ns1.src='https://embed.tawk.to/6448b7554247f20fefedd21a/1guu0o9i4';\r\ns1.charset='UTF-8';\r\ns1.setAttribute('crossorigin','*');\r\ns0.parentNode.insertBefore(s1,s0);\r\n})();\r\n`}</script> */}
        {/* {siteSettings?.config_livechat && siteSettings?.config_livechat.toString()} */}
        {/* {liveChat && <script type="text/javascript">{liveChat.toString()}</script>} */}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
