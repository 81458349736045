import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography, Divider, Button } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAVBAR } from '../../../config';
// components
import Scrollbar from '../../../components/Scrollbar';
import Image from '../../../components/Image';
import useAuth from '../../../hooks/useAuth';
import { useDispatch, useSelector } from '../../../redux/store';
import Iconify from '../../../components/Iconify';

import { GAME_CATEGS } from '../../../utils/const';
import { setSelectedGameTab } from '../../../redux/slices/gameTab';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';
import useLocales from '../../../hooks/useLocales';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useRedirect from '../../../hooks/useRedirect';
import LanguagePopover from '../header/LanguagePopover';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const NavButtons = styled(Button)(({ theme }) => ({
  color: 'white',
  justifyContent: 'start',
  padding: '0 16px',
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const { isAuthenticated } = useAuth();

  const { translate } = useLocales();

  const navigate = useRedirect();

  // const [openDepositWithdrawDialog, setOpenDepositWithdrawDialog] = useState({
  //   open: false,
  //   isDeposit: true,
  // });

  const { gameCategsAndCompanies } = useSelector((x) => ({ ...x.lookup, ...x.game }));

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const categButtons = useMemo(() => {
    const newButtons = [];
    if (gameCategsAndCompanies?.length) {
      gameCategsAndCompanies?.forEach((gc) => {
        const found = GAME_CATEGS?.find((x) => gc.type === x.type);
        if (found) newButtons.push(found);
      });
    }

    return newButtons;
  }, [gameCategsAndCompanies]);

  const makeGameCategory = (_category) => {
    let nav = '';

    if (_category?.slug.toLowerCase().indexOf('slots') !== -1) nav = require('../../../assets/menu/nav-slot.png');

    if (_category?.slug.toLowerCase().indexOf('live-casino') !== -1) nav = require('../../../assets/menu/nav-card.png');

    if (_category?.slug.toLowerCase().indexOf('fishing') !== -1) nav = require('../../../assets/menu/nav-fish.png');

    if (_category?.slug.toLowerCase().indexOf('card') !== -1) nav = require('../../../assets/menu/nav-card.png');

    if (_category?.slug.toLowerCase().indexOf('sports') !== -1) nav = require('../../../assets/menu/nav-sport.png');

    if (_category?.slug.toLowerCase().indexOf('e-sports') !== -1)
      nav = require('../../../assets/menu/nav-e-sports.png');

    return (
      <Box
        key={_category?.slug}
        onClick={() => {
          dispatch(setSelectedGameTab(_category?.slug));
          if (!isDesktop) onCloseSidebar();
          // if (window.location.pathname !== '/home') navigate('/');
          navigate(`/games/${_category?.slug}`);
        }}
        sx={{
          justifyContent: 'flex-start',
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: 0.5,
          cursor: 'pointer',
          p: '8px 12px',
          position: 'relative',
          mb: 1,
        }}
      >
        <Image src={nav} sx={{ width: '20px', mr: '12px', mb: 0 }} alt="History" />
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            fontFamily: 'sans-serif',
            lineHeight: '1.5',
            textAlign: 'left',
            textDecoration: 'none',
            color: '#fff',
          }}
        >
          {_category?.name}
        </Typography>
      </Box>
    );
  };

  const renderContent = (
    <Scrollbar>
      <Stack spacing={1}>
        {/* {isAuthenticated && <AccountAccordion onCloseSidebar={onCloseSidebar} />} */}
        <NavButtons onClick={() => navigate('/games/slots')} variant="text">
          {translate('games')}
        </NavButtons>

        <Divider className="divider-gradient" />

        <NavButtons onClick={() => navigate('/promotion')} variant="text">
          {translate('promotions')}
        </NavButtons>

        <Divider className="divider-gradient" />

        <NavButtons
          onClick={() => {
            if (isAuthenticated) {
              dispatch(
                openTransactionsDialog({
                  open: true,
                  isDeposit: true,
                })
              );

              onCloseSidebar();
            } else {
              navigate('/auth/login');
            }
          }}
          variant="text"
        >
          {translate('wallet')}
        </NavButtons>

        <Divider className="divider-gradient" />

        <NavButtons onClick={() => navigate('/games/live-casino')} variant="text">
          {translate('casino')}
        </NavButtons>

        <Divider className="divider-gradient" />

        {!isAuthenticated && (
          <>
            <NavButtons onClick={() => navigate('/auth/login')} variant="text">
              {translate('login')}
            </NavButtons>

            <Divider className="divider-gradient" />

            <NavButtons onClick={() => navigate('/auth/register')} variant="text">
              {translate('register')}
            </NavButtons>

            <Divider className="divider-gradient" />
          </>
        )}
      </Stack>

      {/* <Box sx={{ flexGrow: 1 }} /> */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: NAVBAR.DASHBOARD_WIDTH,
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              py: 2,
              px: 0,
              boxShadow: '0px 0px 15px 1px rgba(255,0,0,0.75) !important',
              borderRight: '2px solid red',
              background: 'black',
            },
          }}
        >
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mb: 2 }}>
            <Image src={require('../../../assets/app-logo.png')} sx={{ width: '40vw', height: '10vw' }} />
          </Box>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open={isOpenSidebar}
          variant="persistent"
          PaperProps={{
            sx: {
              height: 'calc(100% - 64px)',
              top: 'unset',
              bottom: 0,
              width: NAVBAR.DASHBOARD_WIDTH,
              py: 2,
              px: 0,
              // bgcolor: '#051a12',
              boxShadow:
                '0 1px 2px -2px rgba(255,0,0,.64),0 3px 6px 0 rgba(255,0,0,.48),0 5px 12px 4px rgba(255,0,0,.36)',
              borderRight: '2px solid red',
              boxSizing: 'border-box',
              visibility: 'unset !important',
              transform: 'unset !important',
              transition: (theme) =>
                theme.transitions.create('margin', {
                  duration: theme.transitions.duration.enteringScreen,
                  easing: theme.transitions.easing.easeInOut,
                }),
              ...(isCollapse && {
                marginLeft: '-240px',
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

const NavBottomBtn = ({ icon, image, title, onClick = () => {} }) => (
  <Box
    onClick={onClick}
    sx={{
      justifyContent: 'flex-start',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px',
      cursor: 'pointer',
      py: '5px',
      position: 'relative',
    }}
  >
    {icon ? <Iconify icon={icon} sx={{ width: '24px', height: '24px', mr: '13px', mb: 0, color: '#fff' }} /> : <></>}
    {image ? (
      <Box sx={{ width: '24px', height: '24px', mr: '13px', mb: 0, color: '#fff' }}>
        <Image src={image} />
      </Box>
    ) : (
      <></>
    )}
    <Typography
      sx={{
        fontSize: '13px',
        fontWeight: 100,
        fontFamily: 'sans-serif',
        lineHeight: '1.5',
        textAlign: 'left',
        textDecoration: 'none',
        color: '#fff',
      }}
    >
      {title}
    </Typography>
  </Box>
);
