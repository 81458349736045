// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  // login
  login: `LOGIN`,

  // register
  register: `REGISTER`,

  // home
  game_partners: `Game Partners`,
  responsible_gaming: `Responsible Gaming`,
  supported_bank: `Supported Bank`,
  load_more: `Load More`,

  // game
  choose_your_favourite_x_games: `Choose Your Favourite {{x}} Games`,
  choose_your_favourite_x: `Choose Your Favourite {{x}}`,
  launch: `LAUNCH`,

  // notification
  notifications: `Notifications`,
  you_have_x_unread_messages: `You have {{x}} unread messages`,

  // deposit/withdraw
  drop_or_select_file: `Drop or Select file`,
  drop_files_here: `Drop Files here`,
  bank: `Bank`,
  bank_name: `Bank Name`,
  account_name: `Account Name`,
  account_number: `Account Number`,
  bank_account: `Bank Account`,
  something_went_wrong: `Something went wrong. Please contact support.`,
  deposit_amount_between_x_and_y: `Deposit amount must be between {{x}} and {{y}}`,
  minimum_amount_is_x_y: `Minimum amount is {{x}}{{y}}`,
  payment_method: `Payment Method`,
  mode: `Mode`,
  transfer_to: `Transfer To`,
  select: `Select`,
  account: `Account`,
  amount: `Amount`,
  deposit: `Deposit`,
  withdraw: `Withdraw`,
  amount_no_exceed_balance: `Withdraw Amount should not exceed the available balance`,
  minimum_withdraw_amount_is_x: `Minimum Withdraw Amount is {{x}}`,
  maximum_withdraw_amount_is_x: `Maximum Withdraw Amount is {{x}}`,
  available_balance: `Available Balance`,
  amount_withdrawn: `Amount withdrawn`,
  no_bank_account: `You do not have any account to withdraw to, Add now`,
  add_account: `Add Account`,
  account_name_must_be_4: `Account Name must be greater than 4 in length`,
  please_select_bank: `Please select a Bank Account`,
  account_number_must_be_x: `Account number must be 6-10 in length`,
  minimum_length_is_x: `Minimum length is {{x}}`,
  maximum_length_is_x: `Maximum length is {{x}}`,
  withdraw_information: `Withdraw Information`,
  account_holder_name: `Account Holder Name`,
  name_of_the_account_holder: `Name of the account holder`,
  fill_in_bank_account: `Fill In The Bank Account`,
  please_upload_receipt: `Please upload receipt`,
  deposit_bonus: `Deposit Bonus`,
  bank_transfer: `Bank Transfer`,
  upload_receipt: `Upload Receipt`,
  select_bonus: `Select Bonus`,
  no_promotion: `No Promotion`,

  // transaction history
  transaction_history: `Transaction History`,

  // bet history
  bet_history: `Bet History`,
  game_provider: `Game Provider`,
  bet_count: `Bet Count`,
  bet_amount: `Bet Amount`,
  win_loss: `Win/Loss`,
  rebate: `Rebate`,
  rebates: `Rebates`,
  turnover: `Turnover`,
  game_category: `Game Category`,

  // all
  all: `All`,
  x_is_required: `{{x}} is required`,
  note: `Note`,
  save: `Save`,
  please_select_x: `Please select {{x}}`,
  title: `Title`,
  date: `Date`,
  type: `Type`,
  status: `Status`,
  remark: `Remark`,
  clear: `Clear`,
  date_from: `Date From`,
  date_to: `Date To`,
  category: `Category`,
  search: `Search`,
  new: `New`,
  change_password: `Change Password`,
  logout: `Logout`,
  username: `Username`,
  password: `Password`,
  forgot_password: `Forgot Password`,
  confirm: `CONFIRM`,
  dont_have_account: `Don't have a user account?`,
  contact_us: `Contact Us`,
  game_menu: `Game Menu`,
  bonus: 'Bonus',
  no_data: `No Data`,
  terms_and_condition: 'TERMS & CONDITION',
  game_list: 'GAME LIST',
  play: 'Play',
  games: 'Games',
  casino: 'Casino',
  slots: 'Slots',
  card: 'Card',
  fishing: 'Fishing',
  sport: 'Sport',
  sports: 'Sports',
  bonuses: 'Bonuses',
  account_profile: `Account Profile`,
  email: `Email`,
  full_name: `Full Name`,
  phone_number: `Phone Number`,
  level: `Level`,
  bank_list: `Bank List`,
  in_maintenance: `In Maintenance`,
  birthday: `Birthday`,
  birthday_bonus: `Enter your birthday for a bonus! 🎉🎁`,
  referral_code: `Referral Code`,
  invalid_x: `Invalid {{x}}`,
  extra: `Extra`,
  referral: `Referral`,
  my_referral: `My Referral`,
  my_claim: `My Claim`,
  claim_profit: `CLAIM PROFIT`,
  available_profit: `Available Profit`,
  claimed_profit: `Claimed Profit`,
  all_time_profit: `All Time Profit`,
  referral_note_on_x: `Earn profit every time your friends playing on {{x}}`,
  tier: `Tier`,
  commission: `Commission`,
  referred_member: `Referred Member`,
  profit_earned: `Profit Earned`,
  account_information: `Account Information`,
  invite_friends: `Invite Friends`,
  how_it_work: `How it work?`,
  invite_note: `Get exclusive referral bonuses when your friend signs up using your referral code!`,
  share_via: `Share via`,
  referral_link: `Referral Link`,
  total_profit: `Total Profit`,
  copied: `Copied`,
  nothing_to_claim: `Nothing to claim`,
  claim_history: `Claim History`,
  from: `From`,
  to: `To`,
  choose_date: `Choose Date`,
  select_both_date: `Select both Date From and To`,
  hello: 'Hello',
  my_downline: `My Downline`,
  downline: `Downline`,
  forgot_password_contact: 'Please contact customer support provided below to help you change your password',
  submit: 'Submit',
  current_password: 'Current Password',
  new_password: 'New Password',
  confirm_new_password: 'Confirm New Password',
  already_have_an_account: 'Already have an account?',
  share: 'Share',
  more_info: 'More Info',
  copy_link: 'Copy Link',
  share_to_friend: 'Share To Friend',
  get_bonus: 'Get Bonus',
  copy_your_referral: 'Copy your referral link & send to social media and earn credit from your downline!',
  last_x_digit_bank_ref_number: `Last {{x}} digit of Bank Ref number`,
  bank_ref_number_validation_x: `Bank Ref Number is required and must be {{x}} character length`,
  card_game: 'Card Game',
  e_sports: 'E-Sports',
  instant_game: 'Instant Game',
  sort: 'Sort',
  name: 'Name',
  default: 'Default',
  e_sports_bonus: 'E-Sports Bonus',
  fishing_bonus: 'Fishing Bonus',
  casino_bonus: 'Casino Bonus',
  sports_bonus: 'Sport Bonus',
  slots_bonus: 'Slots Bonus',
  instant_game_bonus: 'Instant Game Bonus',
  card_game_bonus: 'Card Game Bonus',
  all_bonus: 'All Bonus',
  how_to_get_referral_bonus: 'How To Get Referral Friend Bonus?',
  steps_to_claim: 'Steps To Claim Bonus',
  share_your_referral_code:
    '1. Share your referral code to your friends to register account via Viber, Line, Telegram, WhatsApp, Facebook, or Messenger.',
  after_friend_register:
    '2. After friend register with New365, both referral and referral member required to make deposit to claim this bonus.',
  refer_a_friend_bonus: '3. Refer friend bonus calculation:',
  turnover_played: 'Turnover played x Commision % = Referral Friend Bonus',
  example: 'Example:',
  turnover_formula_x: 'Turnover (1,000,000) x 0.002% (Commission %) = 2,000{{x}}',
  note_bonus_only: '*Note: Bonus only able to claim on the next day after 12:00am',
  note_bonus_only_x: '*Note: Bonus only able to claim on the next day after {{x}}',
  access_dashboard: '4. Access dashboard to check entitled profit, commision tier, claim history, and downline.',
  click_this_button_to_claim: 'Click this button to claim Referral Friend Bonus',
  commision_tier: 'Commission Tier',
  member_downline: 'Member Downline',
  bonus_tutorial_video: 'Bonus Tutorial Video',
  popularity: 'Popularity',
  no_balance: 'You do not have enough balance to proceed',
  invalid_captcha: 'Invalid Captcha',
  captcha: 'Captcha',
  claim_bonus: 'Claim Bonus',
  claimed: 'Claimed',
  bonus_claimed: 'Bonus Claimed',
  online_banking: 'Online Banking',
  please_enter_email: 'Please enter your email address to proceed with the password reset process',
  please_select_a_bank: 'Please select a bank',
  deposit_from_bank: 'Deposit From Bank',
  from_selected_promotion: 'from selected promotion',
  from_selected_bank: 'from selected bank',
  press_get_code: 'Press GET CODE Button To Receive Verification Code Via SMS',
  otp_sent: 'Verification Code Sent! Enter the verification code below to continue',
  get_code: 'Get Code',
  verify: 'Verify',
  verification_code: 'Verification Code',
  otp_not_found: 'OTP Function Not Found!',
  network_error: 'Network Error',
  we_have_sent_otp: 'We have sent an OTP to your phone number',
  your_otp_expire: 'Your OTP will expire in',
  didnt_receive: "Didn't receive the message?",
  send_again: 'Send Again',
  resend_again: 'Resend again in',
  invalid_verification: 'Invalid Verification Code!',
  otp_expired: 'OTP expired, please reenter your phone number and try again!',
  otp_expired_redirect: 'OTP expired! redirecting to login',
  captcha_check_failed: 'Captcha Check Failed!',
  verify_phonenumber: 'Verify Phone Number',
  send_otp: 'Send OTP',
  success_verification: 'Your Phone Number has been verified! Please fill the form below to finish your registration.',
  sign_up: 'Sign Up',
  refer_and_reward: 'Refer And Reward',
  minor_note: `To visit this site, make sure you are over 18 and agree`,
  terms_of_service: 'Terms of Service',
  confirm_18: `I confirm that I am 18 years old and I have read the`,
  this_field: 'This field',
  slots_description: `Slot machines generally have three or more "reels", each of which has a numb...`,
  play_now: `Play Now`,
  live_casino: `Live Casino`,
  promotion: `Promotion`,
  promotions: `Promotions`,
  profile: `Profile`,
  wallet: `Wallet`,
  cock_fighting: `Cock Fighting`,
  reset_password: 'Reset Password',
  home: `Home`,
  manage_your_bank_accounts: `Manage Your Bank Accounts`,
  history_of_deposit_withdraw_transactions: `History of Deposit/Withdraw Transactions`,
  transaction_type: `Transaction Type`,
  period_history: `Period History`,
  reset: `Reset`,
  learn_more: `Learn More`,
  number_exists: 'This phone number is already registered!',
  number_not_exists: 'This phone number is not registered!',
  ifsc_code: `IFSC Code`,
  fil_in_ifsc_code: `Fill In IFSC Code`,
  optional: `Optional`,
  app_download: `App Download`,
  payment_draft:
    'If you confirm you have already paid, please wait for a while as the payment might still be processing',
  // TODO: Translate to OTHER LANG
  be_a_vip: `Be a VIP`,
  experience_exlusivity: `Experience Exclusivity Like None Other Special Promotions, Gifts and Rebates - More than you expect!`,
  become_vip1: `As Malaysia's most innovative and all-inclusive online gaming website, NEW365 never stops trying to give members the best. From game options to service standards, NEW365 provides the latest and the finest.`,
  become_vip2: `To take it up a notch, NEW365 is introducing an exclusive VIP program designed to give loyal members more reasons to continue trusting us as their entertainment provider. The five tiers that form the backbone of NEW365 VIP Program are Bronze, Silver, Gold, Platinum & Diamond. As a NEW365 VIP member, you will receive exclusive offers, gifts, and bonuses as well as priority services - Everything you need to have a remarkable gaming experience. Special rates on rebates and tier upgrade bonuses might give you just what you need to achieve your gaming goals!`,
  become_vip3: `The purpose of having a tier-based program is to give members the incentives needed to upgrade progressively. Although we encourage members to achieve VIP tier requirements and keep moving up, our focus is always on members' satisfaction, especially VIP members. Because your satisfaction is our main concern.`,
  to_all_member: `To all members upgrading for the first time`,
  vip_journey1: `1. Regular members will automatically get upgraded to become VIP member after fulfilling the deposit requirement within the given timeframe.`,
  vip_journey2: `2. Allow the system to verify and update your data within 2 working days.`,
  vip_journey3: `3. Kick back, relax and start enjoying your VIP rewards!`,
  benefits_as_our_vip: `Benefits As Our VIPS`,
  vip_upgrading1: `For your safety and security, you are required to provide all necessary details as stated in the NEW365 VIP upgrading policy. It will take up to 2 business working days for our team to review and verify the information provided. We will then update your latest status and information in our system, and after that, your journey as a VIP member will begin!`,
  vip_upgrading2: `To ensure that the transformation goes smoothly and efficiently, please adhere to all upgrading regulations. Feel free to contact our Customer Care agents should you require assistance.`,
  no_data_available: `No Data Available`,
  how_does_it_work: `How Does It Work?`,
  new_vip_journey: `NEW365 VIP Journey`,
  vip_benefits: `VIP Benefits`,
  faq: 'FAQ',
  membership_renewal: 'Membership Renewal',
  about: 'About',
  upgrade: 'Upgrade',
  benefits: 'Benefits',
  special_rebate: 'Special Rebate',
  join_now: 'Join Now'
};

export default en;
