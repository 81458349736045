import React, { useEffect, useMemo, useState } from 'react';
// @mui
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
// hooks
import { useParams, useNavigate } from 'react-router';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import Iconify from '../../../components/Iconify';
import { DEFAULT_COUNTRY_SELECTIONS, allCountry } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function CountryPopover({ sx = {}, CustomBtn, PopoverProps = {} }) {
  const navigate = useNavigate();

  const { allLangs, currentLang, onChangeLang } = useLocales();

  const [open, setOpen] = useState(null);

  const params = useParams();

  const isDesktop = useResponsive('up', 'lg');

  const currentCountry = useMemo(() => {
    if (params?.domain) {
      const findCountry = allCountry.find((x) => x.domain === params.domain);

      if (findCountry) {
        return findCountry;
      }
    }

    return allCountry.find((x) => x.domain === DEFAULT_COUNTRY_SELECTIONS[0]);
  }, [params]);

  useEffect(() => {
    console.log(`currentLang = ${JSON.stringify(currentLang)}`);
  }, []);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeCountry = (country, newLang) => {
    handleClose();

    if (country?.domain === currentCountry?.domain) {
      localStorage.setItem(`lang${params?.domain?.toUpperCase()}`, newLang);
      onChangeLang(newLang);
      window.location.reload();
    } else {
      localStorage.setItem(`lang${country?.domain?.toUpperCase()}`, newLang);
      // window.open('','_self').close()
      // window.open(
      //   `/${country?.domain}/auth/login`,
      //   '_blank'
      //   // 'noopener noreferrer'
      // );

      navigate(`/${country?.domain}/auth/login`, { replace: true });
      window.location.reload();
    }
  };

  const displayCurrentLang = () => {
    const curLang = allLangs?.find((f) => f?.value === localStorage.getItem('i18nextLng'));
    return curLang?.shortLabel ? curLang?.shortLabel?.toUpperCase() : curLang?.label?.toUpperCase();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => handleOpen(e)}>
        <Box sx={{ flex: 1 }}>
          {CustomBtn ? (
            React.cloneElement(CustomBtn, { title: currentCountry?.label })
          ) : (
            <Button variant={'text'} onClick={handleOpen} sx={{ minWidth: 'unset', ...sx }} size="small">
              <Image
                disabledEffect
                src={currentCountry?.icon}
                alt={currentCountry?.label}
                sx={{ width: 28, height: 18.66, mr: 0.5 }}
                ratio={'6 * 2'}
              />

              <Typography fontWeight={700}>{displayCurrentLang()}</Typography>
              {/* {isDesktop ? <Typography fontWeight={700}>{currentCountry?.domain?.toUpperCase()}</Typography> : ''} */}
            </Button>
          )}
        </Box>
      </Box>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 200,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
        {...PopoverProps}
      >
        <Stack spacing={0.75}>
          {allCountry.map((option, itemIndex) => (
            <MenuItem key={itemIndex} selected={option?.domain === currentCountry?.domain}>
              <Image
                key={itemIndex}
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, height: 18.66, mr: 2 }}
                ratio={'18 * 2'}
              />

              {/* {option.label} */}
              {allLangs
                ?.filter((f) => f?.value === 'en' || f?.domain === option?.domain)
                ?.map((item, index) => (
                  <>
                    {index !== 0 && (
                      <Typography key={index} sx={{ mx: '5px', color: 'red', fontWeight: 600 }}>
                        /
                      </Typography>
                    )}
                    <Typography
                      key={index}
                      onClick={() => {
                        handleChangeCountry(option, item?.value);
                      }}
                      sx={{
                        cursor: 'pointer',
                        color:
                          option?.domain === currentCountry?.domain &&
                          localStorage.getItem('i18nextLng') === item?.value
                            ? '#fff'
                            : '#a2aabd',
                        fontWeight:
                          option?.domain === currentCountry?.domain &&
                          localStorage.getItem('i18nextLng') === item?.value
                            ? 500
                            : 400,
                      }}
                    >
                      {item?.label}
                    </Typography>
                  </>
                ))}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
