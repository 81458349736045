import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Box, Button, ButtonBase, Stack, Toolbar, Typography } from '@mui/material';
import { loadCaptchaEnginge } from 'react-simple-captcha';
// hooks
import { m } from 'framer-motion';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { useDispatch, useSelector } from '../../../redux/store';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';

import Image from '../../../components/Image';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import useLocales from '../../../hooks/useLocales';
import { fNumber, fNumberDecimal } from '../../../utils/formatNumber';
import { openLogin, openRegister } from '../../../redux/slices/authPages';
import NotificationsPopover from './NotificationsPopover';
import useRedirect from '../../../hooks/useRedirect';
import useAuth from '../../../hooks/useAuth';
import CountryPopover from './CountryPopover';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  backgroundColor: theme.palette.background.paper,
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar,
  borderBottom: '2px solid red',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  // [theme.breakpoints.up('lg')]: {
  //   height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   ...(isCollapse && {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   }),
  //   ...(isOffset && {
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   }),
  //   ...(verticalLayout && {
  //     width: '100%',
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //     backgroundColor: theme.palette.background.default,
  //   }),
  // },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onToggleCollapse: PropTypes.func,
};

const getCategIcon = (slug) => {
  let nav = '';

  if (slug.toLowerCase().indexOf('slots') !== -1) nav = require('../../../assets/menu/nav-slot.png');

  if (slug.toLowerCase().indexOf('live-casino') !== -1) nav = require('../../../assets/menu/nav-card.png');

  if (slug.toLowerCase().indexOf('fishing') !== -1) nav = require('../../../assets/menu/nav-fish.png');

  if (slug.toLowerCase().indexOf('card') !== -1) nav = require('../../../assets/menu/nav-card.png');

  if (slug.toLowerCase().indexOf('sports') !== -1) nav = require('../../../assets/menu/nav-sport.png');

  if (slug.toLowerCase().indexOf('e-sports') !== -1) nav = require('../../../assets/menu/nav-e-sports.png');

  return nav;
};

export default function DashboardHeader({
  isOpenSidebar = false,
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  // onCloseSidebar,
  onToggleCollapse,
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const { isAuthenticated } = useAuth();

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { isOpenLogin } = useSelector((x) => ({ ...x.authPages, ...x.game }));

  const { memberBalance } = useSelector((x) => x.lookup);

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const navigate = useRedirect();

  useEffect(() => {
    if (localStorage.getItem('referralCode')) {
      setTimeout(() => {
        navigate('/auth/login');
        // dispatch(openLogin());
      }, 1000);
    }

    // Check if got resetpassword token
    if (localStorage.getItem('resetToken')) {
      setOpenResetPasswordDialog(true);
    }
  }, []);

  // useEffect(() => {
  //   if (isOpenLogin)
  //     setTimeout(() => {
  //       loadCaptchaEnginge(4);
  //     }, 1000);
  // }, [isOpenLogin]);

  const logoWidth = useMemo(() => {
    if (isDesktop) {
      return NAVBAR.DASHBOARD_WIDTH;
    }

    if (!isAuthenticated) {
      return '40%';
    }

    return '20%';
  }, [isDesktop, isAuthenticated]);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          pl: { lg: 1 },
          pr: { lg: 2 },
        }}
      >
        {/* {isDesktop && ( */}
        <Stack
          direction="row"
          alignItems={'center'}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: logoWidth,
            px: 1,
          }}
        >
          <Button
            sx={{
              background: 'none',
              p: 0,
              minWidth: '40px',
              color: 'white',
            }}
            color="inherit"
            onClick={() => (isDesktop ? onToggleCollapse() : onOpenSidebar())}
          >
            <Iconify sx={{ fontSize: '30px' }} icon={'ion:menu'} />
          </Button>
          <Box>
            <Image
              onClick={() => navigate('/')}
              src={
                window.innerWidth > 500
                  ? require('../../../assets/app-logo.png')
                  : require('../../../assets/app-logo-sm.png')
              }
              alt="App Logo"
              sx={{
                width: { md: '125px', sm: '10vw', xs: '10vw' },
                height: { md: '31px', sm: '10vw', xs: '10vw' },
                cursor: 'pointer',
                img: { objectFit: 'contain' },
              }}
              ratio={window.innerWidth > 500 ? '6 * 2' : '16 * 4'}
            />
          </Box>
        </Stack>
        {/* )} */}

        <Box sx={{ flexGrow: 1 }} />
        <CountryPopover />
        <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 0.5 }}>
          {!isAuthenticated ? (
            <>
              <ButtonBase
                onClick={() => navigate('/auth/login')}
                variant="contained"
                sx={{ background: 'red', borderRadius: '16px', fontSize: '12px', p: '10px 16px', fontWeight: 700 }}
              >
                {translate('login')}
              </ButtonBase>
              <Button
                component={m.button}
                onClick={() => navigate('/auth/register')}
                animate={{
                  boxShadow: [
                    '0px 0px 5px 1px rgba(255, 0, 0,0.75)',
                    '0px 0px 5px 3px rgba(255, 0, 0,0.75)',
                    '0px 0px 5px 1px rgba(255, 0, 0,0.75)',
                  ],
                  scale: [1, 1.05, 1],
                }}
                transition={{
                  type: 'keyframes',
                  stiffness: 260,
                  damping: 20,
                  repeat: Infinity,
                  repeatDelay: 0,
                  duration: 2.5,
                }}
                sx={{ color: 'red', borderRadius: '16px', px: '16px', fontSize: '12px', border: '1px solid red' }}
              >
                {translate('register')}
              </Button>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  borderRadius: 1,
                  backgroundColor: (theme) => theme.palette.warning.dark,
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', px: 1 }}>
                  <Typography
                    onClick={() =>
                      dispatch(
                        openTransactionsDialog({
                          open: true,
                          isDeposit: true,
                        })
                      )
                    }
                    sx={{
                      cursor: 'pointer',
                      display: { xl: 'block', lg: 'block', md: 'block', sm: 'block', xs: 'block' },
                      mx: 0.5,
                    }}
                    fontWeight={500}
                    fontSize={'0.8rem'}
                  >
                    {/* {`${memberBalance?.currencyCode || ''} ${
                memberBalance?.displayBalance ? fNumberDecimal(memberBalance?.displayBalance) : '0'
              }`} */}
                    {isDesktop
                      ? fNumberDecimal(memberBalance?.displayBalance || 0)
                      : fNumber(memberBalance?.displayBalance || 0)}
                  </Typography>
                  <Iconify icon="ph:caret-down" sx={{ fontSize: '0.6rem' }} />
                </Box>
                <Button
                  sx={{
                    backgroundColor: (theme) => theme.palette.background.default,
                    padding: '3px 10px',
                    borderRadius: 1,
                    cursor: 'pointer',
                    py: 1,
                    color: 'red',
                    minWidth: 'unset',
                  }}
                  onClick={() =>
                    dispatch(
                      openTransactionsDialog({
                        open: true,
                        isDeposit: true,
                      })
                    )
                  }
                >
                  <Iconify icon="material-symbols:wallet" sx={{ fontSize: '1.2rem' }} />
                  {isDesktop ? (
                    <Typography fontWeight={100} fontSize={'0.8rem'}>
                      Deposit
                    </Typography>
                  ) : (
                    ''
                  )}
                </Button>
              </Box>
              <NotificationsPopover />
            </>
          )}
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
