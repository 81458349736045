import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
// form
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { getIdToken } from 'firebase/auth';
// @mui
import { Stack, Alert, Box, Typography, styled, TextField, InputAdornment } from '@mui/material';
// hooks
import { LoadingButton } from '@mui/lab';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import conObj from '../../../utils/connection-assistant';
import useLocales from '../../../hooks/useLocales';
import RegisterOTPForm from '../register/RegisterOTPForm';
import firebaseUtil from '../../../utils/firebaseUtil';
import useRedirect from '../../../hooks/useRedirect';
import { getOtpErrorMessage } from '../../../utils/formatString';
import PhonenumberTextField from '../../../components/PhonenumberTextField';

// ----------------------------------------------------------------------
const FormContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(45deg, rgba(255,191,102,1) 0%, rgba(255,191,102,1) 20%, rgba(255,64,62,1) 40%)',
  padding: '16px',
  borderRadius: '10px',
  border: '2px solid red',
  '& .MuiFormHelperText-root': {
    color: 'yellow',
  },
}));

export default function ForgotPasswordForm() {
  const navigate = useRedirect();

  const { translate } = useLocales();

  const [error, setError] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');

  const [isInit, setIsInit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [captcha, setCaptcha] = useState('');

  const [isValidPhone, setIsValidPhone] = useState(false);

  const [verification, setVerification] = useState({
    number: null,
    id: null,
    otpSent: false,
    isVerified: false,
  });

  useEffect(() => {
    firebaseUtil.initVerifier();
    setIsInit(firebaseUtil.getIsInitialized());
    loadCaptchaEnginge(4);
  }, []);

  const isValidCaptcha = useMemo(() => validateCaptcha(captcha, false), [captcha]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (!phoneNumber) throw new Error(translate('x_is_required', { x: translate('phone_number') }));

      if (!isValidPhone) throw new Error(translate('invalid_x', { x: translate('phone_number') }));

      if (!validateCaptcha(captcha, false)) throw new Error(translate('invalid_captcha'));

      if (verification?.id) {
        const confirmId = await firebaseUtil.handleVerifyOTP(verification?.id, verification?.otp);

        const idToken = await getIdToken(confirmId.user);

        setVerification((prev) => ({ ...prev, isVerified: true }));

        navigate(`/auth/reset-password`, { state: { idToken } });
      }
    } catch (error) {
      setError(getOtpErrorMessage(translate, error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormContainer>
      <Stack spacing={2}>
        {!!error && <Alert severity="error">{error}</Alert>}

        <PhonenumberTextField
          name="userPhoneNumber"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e)}
          fullWidth
          sx={{
            color: 'grey',
            borderColor: '#FF1500',
            '& .MuiOutlinedInput-root': {
              paddingLeft: 0,
            },
          }}
          isValidPhone={setIsValidPhone}
        />

        <TextField
          name="captcha"
          size="small"
          fullWidth
          placeholder={translate('captcha')}
          onChange={(e) => setCaptcha(e.target?.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LoadCanvasTemplateNoReload />
              </InputAdornment>
            ),
          }}
        />

        <RegisterOTPForm
          isForgot={true}
          phoneNumber={phoneNumber}
          setVerification={setVerification}
          verification={verification}
          isValidCaptcha={isValidCaptcha}
        />

        <LoadingButton
          disabled={isLoading || !verification?.id}
          fullWidth
          onClick={() => onSubmit()}
          variant="contained"
          className="red-gradient-btn"
          sx={{ textShadow: '0px 1px 4px #000' }}
          loading={isLoading}
        >
          {translate('confirm')}
        </LoadingButton>
      </Stack>
    </FormContainer>
  );
}
